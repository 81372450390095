import React, {Fragment} from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {Color} from '../../constants';
import {Card, Text12} from '../../widget';
import {AccessTime, CalendarToday, FlashOn} from '@styled-icons/material';
import {SectionHeader} from '../Landing/SectionHeader';
import useFilm from '../../Hooks/useFilm';
import * as L from '../../Utils/Lang';
import Spinner from '../../Components/Spinner';
import {navigate} from 'gatsby-link';
import {FilmContextSection} from '../../Components/FilmContextSection';
import {RectButton} from '../../Components/RectButton';
import {FilmProperty} from '../../Components/FilmProperty';
import {FilmIntro} from '../../Components/FilmIntro';
import moment from 'moment';
import {useOutlet} from 'reconnect.js';
import ReactPlayer from 'react-player';

const FilmDetail = (props) => {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const {film, isLoading} = useFilm({filmId: id});
  const [dimension] = useOutlet('dimension');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <FilmIntro>
        <img
          src={film.image}
          alt="film"
          style={{backgroundColor: Color.orange, objectFit: 'cover'}}
          onError={(e) => {
            e.target.src = 'https://fakeimg.pl/282x400/?text=404&font=lobster';
          }}
        />
        <FilmContextSection film={film}>
          {dimension.rwd === 'mobile' && (
            <Fragment>
              <FilmProperty value={`${film.runTime} 分鐘`}>
                <AccessTime size={12} />
              </FilmProperty>
              <FilmProperty
                value={moment(film.openingDate).format('YYYY / MM / DD')}>
                <CalendarToday size={12} />
              </FilmProperty>
            </Fragment>
          )}
          {dimension.rwd === 'desktop' && (
            <Fragment>
              <FilmProperty value={`${film.runTime} 分鐘`}>
                <Text12 style={{minWidth: 80, textAlign: 'left'}}>
                  電影片長
                </Text12>
              </FilmProperty>
              <FilmProperty
                value={moment(film.openingDate).format('YYYY / MM / DD')}>
                <Text12 style={{minWidth: 80, textAlign: 'left'}}>
                  上映日期
                </Text12>
              </FilmProperty>
              <FilmProperty
                style={{alignItems: 'flex-start'}}
                value={L.d(film.category)}>
                <Text12 style={{minWidth: 80, textAlign: 'left'}}>
                  電影類型
                </Text12>
              </FilmProperty>
              <FilmProperty
                style={{alignItems: 'flex-start'}}
                value={L.d(film.director)}>
                <Text12 style={{minWidth: 80, textAlign: 'left'}}>導演</Text12>
              </FilmProperty>
              <FilmProperty
                style={{alignItems: 'flex-start'}}
                value={L.d(film.cast)}>
                <Text12 style={{minWidth: 80, textAlign: 'left'}}>演員</Text12>
              </FilmProperty>
            </Fragment>
          )}

          <div style={{flex: 1}} />

          {dimension.rwd === 'desktop' && (
            <RectButton
              onClick={async () => {
                await navigate(`/book?id=${id}`);
              }}
              style={{width: '100%'}}
              icon={<FlashOn size={20} />}>
              快速訂票
            </RectButton>
          )}
        </FilmContextSection>
      </FilmIntro>

      {dimension.rwd === 'mobile' && (
        <RectButton
          onClick={async () => {
            await navigate(`/book?id=${id}`);
          }}
          style={{width: '100%'}}
          icon={<FlashOn size={20} />}>
          快速訂票
        </RectButton>
      )}

      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="電影預告"
        caption="trailer"
      />

      <div
        className="play-wrapper"
        style={{
          position: 'relative',
          paddingTop: '56.25%',
        }}>
        <ReactPlayer
          controls
          width="100%"
          height="100%"
          style={{position: 'absolute', top: 0, left: 0}}
          url={film.video}
        />
      </div>

      {dimension.rwd === 'mobile' && (
        <Fragment>
          <SectionHeader
            style={{marginTop: 20, marginBottom: 15}}
            title="電影簡介"
            caption="introduction"
          />

          <Card style={{width: '100%', padding: 15}}>
            <FilmProperty
              style={{alignItems: 'flex-start'}}
              value={L.d(film.category)}>
              <Text12 style={{minWidth: 80, textAlign: 'left'}}>
                電影類型
              </Text12>
            </FilmProperty>

            <FilmProperty
              style={{alignItems: 'flex-start'}}
              value={L.d(film.director)}>
              <Text12 style={{minWidth: 80, textAlign: 'left'}}>導演</Text12>
            </FilmProperty>
            <FilmProperty
              style={{alignItems: 'flex-start'}}
              value={L.d(film.cast)}>
              <Text12 style={{minWidth: 80, textAlign: 'left'}}>演員</Text12>
            </FilmProperty>
          </Card>
        </Fragment>
      )}

      <SectionHeader
        style={{marginTop: 20, marginBottom: 15}}
        title="劇情簡介"
        caption="introduction"
      />

      <Card style={{width: '100%', padding: 15}}>
        <div style={{color: Color.black_50}}>{L.d(film.ratingDescription)}</div>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
`;

export default FilmDetail;
